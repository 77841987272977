import React, { Component } from 'react'
import { PlateSizeGuideModel } from '@models/ProductModels/PlateHoleGuideModel'
import { H2 } from '@styles/Global.styles';
import { 
    PlateHoleGuideHeadingContainer, 
    PlateHoleGuideContainer, 
    PlateHoleGuidePlateContainer, 
    PlateHoleGuidePlateHeadingContainer,
    PlateHoleGuidePlateHeading,
    PlateHoleGuidePlateSize,
    PlateHoleGuidePlateImage,
    PlateHoleGuidePlateImageContainer,
    PlateHoledGuideDescription,
    PlateHoleGuideSegment
} from './PlateHoleGuide.styles';
import theme from '@styles/theme';
import { Segment } from 'semantic-ui-react';

interface PlateHoleGuideProps {
    plates: PlateSizeGuideModel[],
    heading: string
}

interface PlateHoleGuideState {
    plateMaxWidth: number
}


class PlateHoleGuide extends Component<PlateHoleGuideProps, PlateHoleGuideState> {
    state = { plateMaxWidth: 0 }

    componentDidMount() {
        if(this.state.plateMaxWidth === 0){
            let maxWidth = 0;

            this.props.plates.forEach((plateHoleGuide: PlateSizeGuideModel) => {
                if(plateHoleGuide.size_width > maxWidth){
                    maxWidth = plateHoleGuide.size_width
                }
            });
    
            this.setState({
                plateMaxWidth: maxWidth
            });
        }
    }

    render = () => {
        const {plateMaxWidth} = this.state
        const{plates,heading} = this.props;
        return <PlateHoleGuideSegment basic>
        <PlateHoleGuideContainer>
            <PlateHoleGuideHeadingContainer>
                <H2>{heading}</H2>
            </PlateHoleGuideHeadingContainer>
            {
                plates.map((plate) => {
                    return (
                        <PlateHoleGuidePlateContainer>
                            <PlateHoleGuidePlateHeadingContainer>
                                <PlateHoleGuidePlateHeading>
                                    {plate.title}
                                </PlateHoleGuidePlateHeading>
                                <PlateHoleGuidePlateSize>
                                    {plate.size_width}mm x {plate.size_height}mm
                                </PlateHoleGuidePlateSize>
                            </PlateHoleGuidePlateHeadingContainer>
                            <PlateHoledGuideDescription color={theme.brand.colors.darkGrey}>
                                {plate.descripton}
                            </PlateHoledGuideDescription>
                            <PlateHoleGuidePlateImageContainer>
                                <PlateHoleGuidePlateImage alt={plate.title} width={(plate.size_width / plateMaxWidth) * 100} src={plate.plate_image_url} />
                            </PlateHoleGuidePlateImageContainer>
                        </PlateHoleGuidePlateContainer>
                    )
                })
            }
        </PlateHoleGuideContainer>
        </PlateHoleGuideSegment>
    }
}


export default PlateHoleGuide