import React from "react";
import styled from "@emotion/styled";
import mq from '@styles/mq'
import { Container, Segment } from "semantic-ui-react";
import { H4, Paragraph2 } from "@styles/Global.styles";
import theme from "@styles/theme";

export const PlateHoleGuideContainer = styled(Container)`
    ${
        mq({
            width: ['100%','600px','1000px !important'],
            padding:['0px','60px', '100px']
        })
    }
    background: #fff;
`

export const PlateHoleGuideHeadingContainer = styled.div`
    text-align: center;    
    ${
        mq({
            padding: ['40px 0;','60px 25px;','60px 100px;']
        })
    }

    > h2 {
        font-weight: normal;
        line-height: 28px;
        ${
            mq({
                fontSize: ['16px','18px','20px;']
            })
        }
    }
`

export const PlateHoleGuidePlateContainer = styled.div`
    margin-bottom: 80px;
    text-align: left;
`

export const PlateHoleGuidePlateHeadingContainer = styled.div`
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 3px solid ${theme.brand.colors.darkGrey};
`

export const PlateHoleGuidePlateSize = styled(H4)`
    float: right;
    font-weight: normal;
    margin: 0;
    ${
        mq({
            fontSize: ['14px','22px','24px']
        })
    }
`

export const PlateHoleGuidePlateHeading = styled(H4)`
    display: inline-block;    
    margin: 0;
    font-weight: bold;
    ${
        mq({
            fontSize: ['14px','22px','24px']
        })
    }
`
export const PlateHoledGuideDescription = styled(Paragraph2)`
    margin-bottom: 0;
`

export const PlateHoleGuidePlateImage = styled(({width,...others})=><img alt='plate hole guide' {...others} />)`
    width: ${props => props.width}%;
    margin-top: 15px;
`

export const PlateHoleGuidePlateImageContainer = styled.div`
    text-align: center;
`
export const PlateHoleGuideSegment = styled(({darkTheme, ...rest}) => <Segment {...rest} />)`
    background-color: white !important;
    margin-top: -45px !important;
`