import styled from '@emotion/styled';
import {KenticoImage} from '@elements/index';
import mq from '@styles/mq'
import {Container, GridRow, Segment} from 'semantic-ui-react'
import React  from 'react'
export const BannerImageSegment = styled(Segment)`
  && {
    border: none;
    padding: 0;
  }
`

export const BannerContentContainer = styled(({isLargeBanner, ...props})=><GridRow {...props} />)`
  ${props=>mq({
    height: props.isLargeBanner ? ['400px','600px','750px'] : ['270px','300px','356px']
  })}
  position: relative;
  width: 100%;
`;

export const BannerImageContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`

export const BannerImage = styled(({...props})=><KenticoImage {...props} />)`
  width: 100%;
  height: 100%;
`

export const BannerContent = styled(Container)`
  ${mq({
    marginTop: ['-170px','-170px','-356px'], //same as BannerContainer height
    marginBottom: ['40px','40px','120px'], //the margin bottom 
    height:['90px','90px','116px'] //the content area height
  })}
  display:block;
  text-align: center;
  position: relative;
  color: white;
`