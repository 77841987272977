import { mapToButton } from '@utils/mapper'
import { KenticoChoice, KenticoImage } from '@utils/KontentTypes';
import { mapFirstKenticoImage } from '@utils/Helpers';
import { ImageButtonBannerProps } from './ImageButtonBanner';

interface ImageLinkBannerProps {
    image: {
        value: KenticoImage[]
    }
    title: {
        value: string
    }
    button__text: {
        value: string
    }
    button__button_color_theme: {
        value: KenticoChoice[]
    }
    button__button_type: {
        value: KenticoChoice[]
    }
    button__url_slug: {
        value: string
    }
    image_size: {
        value: [
            {
                codename: string
            }
        ]
    }
    show_title: {
        value: [
            {
                name: string
            }
        ]
    }
    show_button: {
        value: [
            {
                name: string
            }
        ]
    }
    image_link: {
        value: string
    }
}


export const mapToImageLinkBanner = (data: ImageLinkBannerProps): ImageButtonBannerProps | undefined => {

    if (data == undefined)
        return undefined;
  
    const { image, title, image_size, image_link, show_button, show_title } = data;
    const isLargeBanner = image_size && image_size.value.length > 0 ? image_size.value[0].codename == 'large_banner' : false;
    const button = mapToButton(data);
    return {
        image: mapFirstKenticoImage(image.value),
        title: title.value,
        button: button,
        isLargeBanner: isLargeBanner,
        imageLink: image_link?.value,
        showButton: show_button?.value[0]?.name === 'Show' ? true: false,
        showTitle: show_title?.value[0]?.name === 'Show' ? true: false
    }
}
