import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from '@components/SEO'
import { SignupBannerWrapper, mapToKiwiVIPVisible } from "@components/SignupBanner";
import { mapToImageLinkBanner, ImageButtonBanner } from '@components/ImageButtonBanner'
import { mapToPlateHoleGuidePlates } from "@mappers/ProductModels/mapper"
import PlateHoleGuide from "@components/PlateHoleGuide/PlateHoleGuide"

const AboutUs = (props: any) => {

  const { elements } = props.data.kontentItemPlateHoleGuidePage;
  const seo = mapToSEO(elements);
  const kiwiVip = mapToKiwiVIPVisible(elements);
  const metadata = mapToPageHeader(elements);
  const imageLink = mapToImageLinkBanner(elements.image_link_banner.linked_items[0]?.elements);
  const holeGuidePlates = mapToPlateHoleGuidePlates(elements.plates.linked_items);

  return (<Layout location={props.location}>
    <SEO {...seo} />
    <Banner {...metadata} isBiggerBanner={false} />
    <PlateHoleGuide plates={holeGuidePlates} heading={elements.heading.value} />
    {
      imageLink &&
      <ImageButtonBanner {...imageLink} />
    }
    {
      kiwiVip.visible &&
      <SignupBannerWrapper version={kiwiVip.version} />
    }
  </Layout>);

}

export const query = graphql`
{
    kontentItemPlateHoleGuidePage {
      elements {
        general_page_content__header_image {
          value {
            description
            height
            name
            size
            type
            url
            width
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
            name
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
            name
          }
        }
        general_page_content__title {
          value
        }
        general_page_content__title_position {
          value {
            name
            codename
          }
        }
        heading {
          value
        }
        image_link_banner {
          linked_items {
            ... on KontentItemImageLinkBanner {
              id
              elements {
                button__button_color_theme {
                  value {
                    codename
                    name
                  }
                }
                button__button_type {
                  value {
                    name
                    codename
                  }
                }
                button__text {
                  value
                }
                button__url_slug {
                  value
                }
                image {
                  value {
                    description
                    height
                    name
                    size
                    type
                    url
                    width
                  }
                }
                title {
                  value
                }
              }
            }
          }
        }
        plates {
          linked_items {
            ... on KontentItemPlateHoleGuidePlate {
              elements {
                description {
                  value
                }
                plate_image {
                  value {
                    description
                    height
                    name
                    size
                    type
                    url
                    width
                  }
                }
                size_height {
                  value
                }
                size_width {
                  value
                }
                title {
                  value
                }
              }
            }
          }
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url {
          value
        }
      }
    }
  }
  

`
export default AboutUs;
